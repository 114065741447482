.addExercise {
    max-width: 700px;
    margin: auto;

    .ui.icon.input {
        margin-bottom: 15px;
    }

    .exerciseList {
        .posturalGroupTitle {
            background-color: #EBEBEB;
            height: 30px;
            margin-bottom: 15px;
            padding: 0 15px;

            span {
                font-weight: 700;
                font-size: 1em;
                line-height: 2em;
            }
        }

        .selectableListItem {
            img.ui.avatar.image {
                border-radius: 5px;
                border: 3px double #F4F4F4;
                height: 3.5em;
                width: 3.5em;
            }
        }
    }
}