.proPracticesBox {
    margin-top: 15px;
    padding: 15px;
    background-color: white;
    border-radius: 5px;

    div.ui.divided.relaxed.list {
        div.item {
            padding-top: 0.75em;
            padding-bottom: 0.75em;

            div.content {
                width: calc(100% - 112px);
                display: inline-block;

                .header, .description {
                    white-space: nowrap;
                    overflow: hidden;
                    text-overflow: ellipsis;
                }
            }

            button.ui.button {
                top: -10px;
            }
        }
    }
}