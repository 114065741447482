.register {
    display: flex;

    img.ui.image.background {
        position: absolute;
        width: 100%;
        height: 100%;
        object-fit: cover;
    }

    .registerTab {
        position: relative;
        display: inline-block;
        width: 50%;
        height: 100vh;
        padding: 5%;

        .registerForm {
            position: relative;
            margin: auto;
            padding: 50px 30px;
            background-color: white;
            border-radius: 20px;
            top: 50%;
            transform: translateY(-50%);

            .goBack {
                padding: 0;
            }

            img.ui.image.logo {
                width: 135px;
                margin: 30px auto;
            }

            .header {
                text-align: center;
                margin-bottom: 20px;

                .stepText {
                    display: block;
                    font-weight: 700;
                    font-size: 0.9em;
                    line-height: 1.1em;
                }

                .title {
                    display: block;
                    font-weight: 600;
                    font-size: 2.25em;
                    line-height: 1.5em;
                }

                .subtitle {
                    display: block;
                    font-size: 1.25em;
                    line-height: 1.5em;
                }
            }

            .ui.form {
                margin: 30px 0;

                button.ui.button {
                    margin: 15px 0;
                }
            }

            .hasAccount {
                text-align: center;
            }
        }
    }

    .commercialTips {
        position: relative;
        display: flex;
        flex-direction: column;
        justify-content: center;
        width: 50%;
        padding: 30px;

        .benefit {
            img {
                width: 60px;
                display: inline-block;
                margin-right: 15px;
            }

            .benefitText {
                display: inline-block;
                text-align: center;
                vertical-align: middle;
                width: calc(100% - 75px);
                margin-bottom: 30px;

                .title {
                    display: block;
                    font-size: 1.5em;
                    line-height: 1.5em;
                    font-weight: 600;
                }

                .text {
                    display: block;
                    font-size: 1.25em;
                    line-height: 1.25em;
                }
            }
        }
    }

    @media (max-width: '768px') {
        .registerTab {
            width: 100%;
            height: 100%;
            padding: 0;

            .registerForm {
                border-radius: 0;
                height: 100vh;
                padding: 15px;
                vertical-align: middle;
                top: 0;
                transform: none;
            }
        }

        .commercialTips {
            display: none;
        }
    }
}