.image-enhancer {
    width: 100%;
    height: 100%;
    max-width: 460px;
    margin: 0 auto;

    img{
        width: 100%;
        height: 100%;
        object-fit: contain;
    }
}