.stepInfo {
  font-size: 0.8em;
  line-height: 1em;
  padding-bottom: 10px;
  display: block;
}

.addGroupTitle {
  padding-bottom: 10px;
  display: block;
  font-size: 1.25em;
  font-weight: 700;
  line-height: 1.5em;
}

.addStudentWarning {
  display: flex;
  align-items: center;
}

.addStudentWarning span {
  font-weight: 500;
  font-size: 18px;
  line-height: 30px;
  color: #ffcc49;
}
