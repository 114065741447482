.setupSubscription {
    padding-bottom: 25px;

    .ui.segment {
        div.licenseType, div.studentMax, div.expiredDate {
            display: inline-block;
            width: calc(33% - 27px);
            padding: 15px;
            vertical-align: top;
            text-align: center;

            span {
                display: block;

                &.title {
                    font-weight: 700;
                    font-size: 0.9em;
                    line-height: 1em;
                }

                &.text {
                    font-size: 1.25em;
                    line-height: 1.5em;
                }
            }
        }

        button.ui.button.first {
            margin-left: 100px;
            margin-right: 15px;
        }

        @media (max-width: '768px') {
            div.licenseType {
                width: calc(100% - 85px);
            }

            div.studentMax, div.expiredDate {
                width: 50%;
            }

            button.ui.button {
                display: block;
                width: 100%;
                margin-bottom: 0;

                &.first {
                    margin-left: 0;
                    margin-right: 0;
                    margin-bottom: 10px;
                }
            }

            .hideOnMobile {
                display: none !important;
            }
        }
    }
}