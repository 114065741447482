.userNotes {
    position: relative;
    top: 100px;
    padding-bottom: 15px;

    span.title {
        font-size: 1.5em;
        font-weight: 700;
        line-height: 2em;
    }

    .box {
        background-color: #F4F4F4;
        border-radius: 5px;
        padding: 30px;
        margin-bottom: 30px;

        span.subtitle {
            display: block;
            font-size: 1em;
            line-height: 1.25em;
            color: #747474;
        }

        form.ui.form {
            .field {
                width: calc(100% - 100px);
                margin-bottom: 0;
            }

            button.ui.fluid.button {
                float: right;
                width: 85px;
                top: -38px;
                left: 5px;
            }
        }
    }

    @media (max-width: '768px') {
        top: 0;

        span.title {
            display: block;
        }

        .box {
            padding: 15px;

            span.subtitle {
                display: block;
                font-size: 1em;
                line-height: 1.25em;
                font-weight: 700;
                color: black;
            }

            form.ui.form {
                .field {
                    width: 100%;
                    margin-bottom: 15px;
                }

                button.ui.fluid.button {
                    float: none;
                    width: 100%;
                    top: 0;
                    left: 0;
                }
            }
        }
    }
}