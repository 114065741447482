.landing {
    position: relative;
    color: #323232;

    .ui.container {
        position: relative;

        .header {
            position: relative;

            .headerScreenshot {
                position: absolute;
                top: 10%;
                left: 50%;
                width: 50%;
            }

            .headerText {
                position: absolute;
                top: 12%;
                left: 7%;
                width: 45%;

                .title {
                    display: block;
                    font-weight: 700;
                    font-size: 3em;
                    line-height: 1.25em;
                    color: white;
                }

                .text {
                    display: block;
                    font-size: 1.5em;
                    line-height: 1.25em;
                    color: white;
                }

                .headerButton {
                    margin-top: 30px;
                }
            }

            @media (max-width: '1200px') {
                .headerText {
                    top: 10%;
                    left: 5%;
                    width: 55%;

                    .title {
                        font-size: 2.5em;
                        line-height: 1em;
                    }

                    .text {
                        font-size: 1.25em;
                        line-height: 1.25em;
                    }
                }
            }

            @media (max-width: '768px') {
                .headerBackground {
                    display: none;
                }

                .headerText {
                    position: relative;
                    width: 100%;
                    top: 0;
                    left: 0;
                    background-color: #2F3544;
                    padding: 30px 20px 300px 20px;
                    border-radius: 10px;

                    .title {
                        font-size: 2em;
                        line-height: 1.25em;
                    }
                }

                .headerScreenshot {
                    position: relative;
                    top: -265px;
                    max-width: 600px;
                    left: 0;
                    width: 100%;
                    margin: auto;
                }
            }
        }

        .whatIsYogabot {
            position: relative;
            text-align: center;
            top: 150px;

            .title {
                display: block;
                font-size: 2.25em;
                line-height: 1.75em;
                font-weight: 600;
            }

            .text {
                display: block;
                font-size: 1.5em;
                line-height: 1.5em;
            }

            .apps {
                padding: 30px 60px;

                .app {
                    display: inline-block;
                    width: 50%;
                    vertical-align: middle;

                    img {
                        display: inline-block;
                        width: 88px;
                        margin-right: 15px;
                    }

                    .appText {
                        display: inline-block;
                        text-align: left;
                        vertical-align: middle;
                        width: calc(100% - 105px);

                        .title {
                            display: block;
                            font-size: 1.5em;
                            line-height: 1.25em;
                            font-weight: 400;
                        }

                        .text {
                            display: block;
                            font-size: 1em;
                            line-height: 1.25em;
                            font-weight: 400;
                        }
                    }
                }
            }

            @media (max-width: '768px') {
                top: -200px;

                .apps {
                    padding: 0;

                    .app {
                        display: block;
                        margin: auto;
                        padding: 30px 0;
                        width: 100%;
                    }
                }
            }

        }

        .functionalities {
            position: relative;
            text-align: center;
            top: 250px;

            .title {
                display: block;
                font-size: 2.25em;
                line-height: 1.25em;
                font-weight: 600;
            }

            .text {
                display: block;
                font-size: 1.5em;
                line-height: 1.5em;
            }

            @media (max-width: '768px') {
                top: -125px;
            }
        }

        &.increaseIncomeContainer {
            position: relative;
            text-align: left;
            top: 440px;

            .increaseIncome {

                .title {
                    display: block;
                    font-size: 2.25em;
                    line-height: 1.5em;
                    font-weight: 600;
                }

                .text {
                    display: block;
                    font-size: 1.5em;
                    line-height: 1.5em;
                }

                .lessons {
                    padding: 30px 30px;

                    .lesson {
                        display: inline-block;
                        width: 50%;
                        vertical-align: middle;
                        padding: 0 15px;

                        img {
                            display: inline-block;
                            width: 88px;
                            margin-right: 15px;
                        }

                        .lessonText {
                            display: inline-block;
                            text-align: left;
                            vertical-align: middle;
                            width: calc(100% - 105px);

                            .title {
                                display: block;
                                font-size: 1.25em;
                                line-height: 1.25em;
                                font-weight: 400;
                            }

                            .text {
                                display: block;
                                font-size: 1.5em;
                                line-height: 1.5em;
                                font-weight: 700;
                            }
                        }
                    }
                }
            }

            @media (max-width: '768px') {
                top: 50px;

                .increaseIncome {

                    .lessons {
                        padding: 0;

                        .lesson {
                            display: block;
                            margin: auto;
                            padding: 30px 0;
                            width: 100%;
                        }
                    }
                }
            }
        }

        &.benefitsAndHowItWorksContainer {
            position: relative;
            text-align: center;
            top: 500px;

            .benefits {
                .title {
                    display: block;
                    font-size: 2.25em;
                    line-height: 1.5em;
                    font-weight: 600;
                }

                .benefitList {
                    position: relative;
                    padding: 60px 15px;
                    width: 50%;
                    left: 50%;

                    .benefit {
                        img {
                            width: 80px;
                            display: inline-block;
                            margin-right: 15px;
                        }

                        .benefitText {
                            display: inline-block;
                            text-align: center;
                            vertical-align: middle;
                            width: calc(100% - 100px);
                            margin-bottom: 30px;

                            .title {
                                display: block;
                                font-size: 1.5em;
                                line-height: 1.25em;
                                font-weight: 400;
                            }

                            .text {
                                display: block;
                                font-size: 1em;
                                line-height: 1.25em;
                                font-weight: 600;
                            }
                        }
                    }
                }

                @media (max-width: '1200px') {
                    .benefitList {
                        width: 60%;
                        left: 40%;
                    }
                }
            }

            .howItWorks {
                position: relative;
                text-align: center;
                top: 60px;

                .title {
                    display: block;
                    font-size: 2.25em;
                    line-height: 1.5em;
                    font-weight: 600;
                }

                .howItWorkList {
                    .howItWorkItem {
                        display: inline-block;
                        width: 25%;
                        vertical-align: top;
                        padding: 40px 15px;

                        img {
                            width: 88px;
                            margin: auto;
                            margin-bottom: 25px;
                        }

                        .howItWorkText {
                            .title {
                                font-size: 1.25em;
                                line-height: 1.25em;
                                font-weight: 400;
                            }
                        }
                    }
                }

                @media (max-width: '768px') {
                }
            }

            @media (max-width: '768px') {
                top: 100px;

                .benefits {
                    .benefitList {
                        width: 100%;
                        left: 0;
                    }
                }

                .howItWorks {
                    top: 0;

                    .howItWorkList {
                        .howItWorkItem {
                            display: inline-block;
                            width: 100%;
                            padding: 40px 0;
                        }
                    }
                }
            }
        }

        &.beforeFooterLastContainer {
            position: relative;
            top: 625px;
            padding: 100px 0;

            .yogaCenter {

                img {
                    display: inline-block;
                    width: 50%;
                    max-width: 505px;
                }

                .yogaCenterText {
                    display: inline-block;
                    width: 50%;
                    padding-left: 50px;
                    vertical-align: middle;

                    .title {
                        display: block;
                        font-size: 2.25em;
                        line-height: 1.5em;
                        font-weight: 600;
                    }

                    .text {
                        display: block;
                        font-size: 1.5em;
                        line-height: 1.25em;
                    }

                    .contractButton {
                        margin-top: 15px;
                    }
                }
            }

            .workshops {
                border-radius: 10px;
                position: relative;
                padding: 100px 0;
                text-align: left;

                img {
                    position: absolute;
                    width: 100%;
                }

                .workshopsText {
                    position: relative;
                    padding: 50px;

                    .title {
                        display: block;
                        font-size: 3em;
                        line-height: 1.25em;
                        font-weight: 700;
                    }

                    .text {
                        display: block;
                        font-size: 1.5em;
                        line-height: 1.5em;
                    }
                }
            }

            @media (min-width: '992px') {
                .workshops {
                    .workshopsText {
                        width: 53%;
                    }
                }
            }

            @media (max-width: '768px') {
                top: 160px;

                .yogaCenter {
                    img {
                        width: 100%;
                        max-width: 100%;
                    }

                    .yogaCenterText {
                        width: 100%;
                        padding-left: 0;
                        padding-top: 50px;
                        text-align: center;
                    }
                }

                .workshops {
                    background-color: #2F3544;
                    margin-top: 50px;
                    margin-bottom: 50px;
                    padding: 25px 0;
                    color: white;

                    img {
                        display: none;
                    }

                    .workshopsText {
                        padding: 20px;
                        .title {
                            font-size: 2em;
                        }

                        .text {
                            font-size: 1.25em;
                        }
                    }
                }
            }
        }
    }

    .engage {
        position: relative;
        text-align: center;
        padding: 100px 0;
        top: 350px;
        background-color: rgba(0, 181, 149, 0.75);
        color: white;
        .ui.container {
            position: relative;

            .title {
                display: block;
                font-size: 2.25em;
                line-height: 1.5em;
                font-weight: 600;
            }

            .text {
                display: block;
                font-size: 1.5em;
                line-height: 1.2em;
            }

            .advantages {
                padding: 30px 0;

                .advantage {
                    display: inline-block;
                    width: 33%;
                    vertical-align: middle;
                    padding: 0 15px;

                    img {
                        display: inline-block;
                        width: 88px;
                        margin-right: 15px;
                    }

                    .advantageText {
                        display: inline-block;
                        text-align: left;
                        vertical-align: middle;
                        width: calc(100% - 105px);

                        .title {
                            display: block;
                            font-size: 1.5em;
                            line-height: 1.25em;
                            font-weight: 400;
                        }

                        .text {
                            display: block;
                            font-size: 1em;
                            line-height: 1.25em;
                            font-weight: 400;
                        }
                    }
                }
            }
        }

        @media (max-width: '768px') {
            padding: 50px 0;
            top: -25px;

            .ui.container {
                .advantages {
                    padding: 0;

                    .advantage {
                        display: block;
                        margin: auto;
                        padding: 30px 0;
                        width: 100%;
                    }
                }
            }
        }
    }

    .benefitsAsanaContainer {
        position: relative;
        top: 610px;

        .ui.image.benefitsAsana {
            position: absolute;
            width: 50%;
            border-top-right-radius: 20px;
            border-bottom-right-radius: 20px;
            max-width: 670px;
        }

        @media (max-width: '1200px') {
            .ui.image.benefitsAsana {
                height: 750px;
                max-width: 40%;
                object-fit: cover;
            }
        }

        @media (max-width: '768px') {
            display: none;
        }
    }

    .pricePlans {
        position: relative;
        top: 620px;
        background-color: #32425B;
        padding: 100px 30px;
        color: white;
        text-align: center;

        .title {
            display: block;
            font-size: 2.25em;
            line-height: 1.5em;
            font-weight: 600;
        }

        .text {
            display: block;
            font-size: 1.5em;
            line-height: 1.2em;

            &.countdownExpired {
                text-decoration: line-through;
            }
        }

        .periodicitySelector {
            background-color: #182943;
            margin: 50px auto;
            width: 200px;
            border-radius: 5px;
            padding: 5px;

            .selector {
                font-size: 1em;
                font-weight: 400;
                line-height: 1.25em;
                color: #989898;
                padding: 10px 0;
                display: inline-block;
                width: 50%;
                cursor: pointer;

                &.active {
                    color: #00B595;
                    background-color: #FFFFFF;
                    border-radius: 3px;
                }
            }
        }

        .planCards {
            display: flex;
            justify-content: center;
        }

        @media (max-width: '850px') {
            .planCards {
                justify-content: flex-start;
                overflow-x: auto;

                &::-webkit-scrollbar {
                    display: none;
                }
            }
        }

        @media (max-width: '768px') {
            top: 150px;
        }
    }

    .faq {
        background-color: #F5F5F5;
        position: relative;
        top: 550px;

        .ui.container {
            padding: 100px 20px;
            text-align: left;

            .title {
                text-align: center;
                display: block;
                font-size: 2em;
                line-height: 1.5em;
                font-weight: 600;
            }
        }

        @media (max-width: '768px') {
            top: 75px;
        }
    }

    .footer {
        background-color: #32425B;
        position: relative;
        top: 550px;
        padding: 50px 0;

        .ui.container {
            position: relative;
            display: flex;
            flex-wrap: wrap;

            .link {
                flex-grow: 1;
                width: 33%;
                padding: 10px 15px;

                a {
                    color: white;
                    font-size: 1.25em;
                    line-height: 1.25em;
                }
            }
        }

        @media (max-width: '768px') {
            top: 50px;

            .ui.container {
                .link {
                    flex-grow: 1;
                    width: 100%;
                }
            }
        }
    }
}