.userBiometricMatrix {
    position: relative;
    top: 100px;

    span.title {
        font-size: 1.5em;
        font-weight: 700;
        line-height: 2em;
    }

    span.updatedAt {
        display: block;
        float: right;
        font-size: 0.75em;
        line-height: 4em;
    }

    .box {
        background-color: #F4F4F4;
        border-radius: 5px;
        padding: 30px;
        margin-bottom: 30px;

        span.title {
            display: none;
            font-size: 1em;
            font-weight: 700;
            line-height: 1.25em;
        }

        span.subtitle {
            display: inline-block;
            width: calc(100% - 355px);
            font-size: 1em;
            line-height: 1.25em;
            color: #747474;
        }

        span.subtitle2 {
            display: inline-block;
            width: 350px;
            font-size: 1em;
            line-height: 1.25em;
            color: #747474;
        }

        span.updatedAt {
            display: none;
            line-height: 1.75em;
            float: none;
        }

        div.matrixBox {
            display: flex;

            div.matrixContainer {
                display: inline-block;
                margin-top: 15px;
                width: calc(100% - 365px);
                margin-right: 15px;
                background-color: white;
                border-radius: 5px;

                .shape {
                    width: 50%;
                    display: inline-block;
                    padding: 15px;
                }
            }

            div.indicators {
                position: relative;
                display: inline-block;
                width: 365px;

                span.sectionSubtitle {
                    display: none;
                    font-size: 1em;
                    font-weight: 700;
                    line-height: 1.25em;
                }

                div.indicatorsBars {
                    margin-top: 15px;
                    width: 100%;
                    background-color: white;
                    border-radius: 5px;
                    padding: 15px;

                    span.title {
                        font-size: 1em;
                        line-height: 1.25em;
                        display: inline;
                    }

                    span.value {
                        font-size: 1em;
                        line-height: 1.25em;
                        float: right;
                        display: inline;
                    }

                    div.bar {
                        height: 10px;
                        background: rgba(0, 216, 178, 1);
                        margin: 15px 0;
                        border-radius: 0 5px 5px 0;

                        &:last-child {
                            margin-bottom: 0;
                        }
                    }
                }
            }
        }

        span.targetsSubtitle, span.yogabotPracticeSubtitle {
            display: inline-block;
            margin-top: 30px;
            width: 100%;
            font-size: 1em;
            line-height: 1.25em;
            color: #747474;
        }

        div.matrixData, div.yogabotPracticeList {
            width: 100%;
            font-size: 1em;
            line-height: 1.25em;

            span.sectionSubtitle {
                display: none;
                font-size: 1em;
                font-weight: 700;
                line-height: 1.25em;
            }
        }
    }

    @media (max-width: '768px') {
        top: 0;

        span.title, span.updatedAt {
            display: none;
        }

        .box {
            padding: 15px;

            span.title, span.updatedAt {
                display: block;
            }

            span.subtitle, span.subtitle2, span.targetsSubtitle, span.yogabotPracticeSubtitle {
                display: none;
            }

            span.updatedAt {
                display: block;
                line-height: 1.75em;
                float: none;
            }

            div.matrixBox {
                flex-direction: column;

                div.matrixContainer {
                    max-width: 100%;
                    width: 100%;
                }

                div.indicators {
                    width: 100%;
                    padding: 30px 0 0 0;

                    span.sectionSubtitle {
                        display: inline-block;
                    }
                }
            }

            div.matrixData, div.yogabotPracticeList {
                span.sectionSubtitle {
                    display: block;
                    margin-top: 30px;
                }
            }
        }
    }
}