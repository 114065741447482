.planCard {
    position: relative;
    margin-top: 60px;
    width: 190px;
    height: 340px;
    background-color: white;
    border-radius: 18px;
    color: black;
    box-sizing: border-box;
    margin-right: 20px;
    flex-shrink: 0;
    text-align: left;

    &:last-child {
        margin-right: 0;
    }

    img {
        position: relative;
        width: 49px;
        top: 22px;
        left: 22px;
    }

    &::before {
        content: attr(data-ribbon);
        position: absolute;
        font-weight: 700;
        font-size: 0.75em;
        line-height: 2.5em;
        bottom: 341px;
        left: 85px;
        transform-origin: bottom left;
        background-color: transparent;
        border: 25px solid transparent;
        border-bottom: 25px solid #FFE600;
        display: block;
        box-sizing: border-box;
        box-shadow: 1px 5px 2px -3px #000;
        height: 0;
        width: 150px;
        transform: rotate(45deg);
        text-align: center;
    }

    &.hideRibbon::before {
        display: none;
    }

    .planTitle {
        position: absolute;
        top: 73px;
        left: 22px;
        font-weight: 700;
        font-size: 1.5em;
        line-height: 1.75em;
    }

    .manageText {
        position: absolute;
        top: 113px;
        left: 22px;
        font-size: 1em;
        line-height: 1.25em;
        width: 150px;
    }

    .oldPrice {
        position: absolute;
        top: 175px;
        left: 22px;
        font-size: 0.75em;
        line-height: 1em;
        text-decoration: line-through;
    }

    .priceBox {
        position: absolute;
        top: 175px;
        left: 22px;

        .newPrice {
            position: relative;
            font-weight: 700;
            font-size: 2em;
            line-height: 2.5em;
        }

        .periodicity {
            position: relative;
            font-size: 1em;
            line-height: 1.25em;
        }
    }

    .forEver {
        position: absolute;
        top: 232px;
        left: 22px;
        font-size: 1em;
        line-height: 1.25em;
    }

    .contractButton {
        position: absolute !important;
        width: 145px;
        top: 266px;
        left: 22px;
    }
}