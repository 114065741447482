.exerciseList {
    max-width: 700px;
    margin: auto;

    div.exercisesEmpty {
        padding: 15px 0;
        color: #747474;
        font-size: 1em;
        font-weight: 500;
        line-height: 1.25em;
    }
}

.addExercise {
    .ui.button {
        padding: 0;
    }
}