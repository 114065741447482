.checkout {
    .ui.segment {
        h3 {
            color: var(--brand-primary);
            text-transform: uppercase;
            text-align: center;

            @media only screen and (max-width: 420px) {
                font-size: 20px;
            }
        }

        .stripe-block {
            max-width: 500px;
            width: 100%;
            margin: 0 auto;

            .infoPayment {
                background-color: var(--brand-secondary);
                padding: 10px;
                margin-top: 40px;
                display: flex;
                width: 100%;
                justify-content: space-between;
                align-items: center;

                i.payment.icon.paymentIcon::before {
                    font-size: 30px;
                    color: white;
                }

                .pay-info {
                    text-align: right;

                    .title-price {
                        display: block;
                    }

                    span {
                        color: white;
                        margin-bottom: 5px;

                        &.info-price {
                            font-size: 24px;
                        }
                    }
                }
            }

            .blocks-separator {
                box-shadow: 0 4px 6px rgba(50, 50, 93, 0.11), 0 1px 3px rgba(0, 0, 0, 0.08);
                padding: 20px;
                border-radius: 4px;
                margin-bottom: 20px;

                .title {
                    color: var(--brand-primary);
                }

                .cl-12 {
                    width: 75%;
                    padding-left: .5em;
                    padding-right: .5em;
                }

                .cl-4 {
                    width: 25%;
                    padding-left: .5em;
                    padding-right: .5em;
                }
            }

            input, .StripeElement {
                margin: 10px 0 20px 0;
                max-width: 500px;
                padding: 10px 14px;
                box-shadow: rgba(50, 50, 93, 0.14902) 0px 1px 3px,
                rgba(0, 0, 0, 0.0196078) 0px 1px 0px;
                border-radius: 5px;
                background: white;
            }
        }
    }
}