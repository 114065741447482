.workshopsContainer {
    text-align: center;
    position: relative;
    display: flex;
    justify-content: center;
    flex-direction: column;
    margin: 50px 0;
    padding: 15px;

    .ui.button.goBackButton {
        margin: 0 calc(100% - 65px) 20px 0;
        padding: 0;
    }

    .title {
        text-align: center;
        display: inline-block;
        font-size: 2em;
        line-height: 1.5em;
        font-weight: 600;
    }

    .workshop {
        .title {
            display: block;
            text-align: center;
            font-size: 1.75em;
            font-weight: 600;
            line-height: 1.75em;
        }

        .subtitle {
            display: block;
            text-align: center;
            font-size: 1.5em;
            font-weight: 500;
            line-height: 1.75em;
        }

        .description {
            display: block;
            text-align: center;
            font-size: 1.25em;
            line-height: 1.75em;
        }

        .comingSoon {
            display: block;
            text-align: center;
            font-size: 2.25em;
            font-weight: 600;
            line-height: 1.75em;
            color: white;
            background-color: #00B595;
            width: 350px;
            border-radius: 5px;
            margin: auto;
        }

        iframe {
            max-width: 500px;
            height: 100%;
        }
    }
}