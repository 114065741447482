.enterpriseContact {
    text-align: center;

    .enterpriseContactContainer {
        position: relative;
        display: flex;
        justify-content: center;
        flex-direction: column;
        margin: 50px 0;
        padding: 15px;

        .goBackButton {
            margin: 0 calc(100% - 65px) 20px 0;
            padding: 0;
        }

        .title {
            text-align: center;
            display: inline-block;
            font-size: 2em;
            line-height: 1.5em;
            font-weight: 600;
        }

        .bodyText {
            text-align: center;
            display: inline-block;
            font-size: 1.5em;
            line-height: 1.25em;
        }
    }

    iframe {
        border: none;
        height: 700px;
        width: 100%;
    }

    .lastGoBackButton {
        margin: 25px auto;
    }
}