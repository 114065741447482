.sequenceFoldersModalList{
    aspect-ratio: 9 / 10;
}

.sequenceFolderItemContent {
    display: flex;
    align-items: center;
    gap: 1rem;
}

.sequenceFolderItemContent .ui.button {
    margin: 0;
    margin-left: auto;
}

.sequenceFolderItemContent .ui.icon.button {
    margin-left: 0;
}