.customFilterComponent {
    width: 100%;

    .ui.selection.dropdown>.search.icon  {
        padding: .71666667em;
    }

    .customFilterDropdown{
        width: 100%;
    }

    @media (min-width: 768px) {
        max-width: 455px;
    }
}