.userAssignedList {
    position: relative;
    margin-top: 15px;

    span.title {
        font-size: 1.5em;
        font-weight: 700;
        line-height: 2em;
    }

    div.addButton {
        margin-bottom: 15px;
    }

    .box {
        border-radius: 5px;
        margin-bottom: 30px;

        span.title {
            display: none;
            font-size: 1em;
            font-weight: 700;
            line-height: 1.25em;
        }

        .ui.selection.list {
            .item.selectableListItem {
                border-bottom: 1px solid #EBEBEB;

                @media (min-width: '768px') {
                    border-bottom: 0;
                }
            }
        }

        div.addButton {
            padding-left: 11px;

            button.ui.circular.icon.button {
                background-color: #F4F4F4;
                padding: 1em;
            }
        }
    }

    @media (max-width: '768px') {
        span.title {
            display: none;
        }

        .box {
            padding: 15px;
            background-color: #F4F4F4;

            span.title {
                display: block;
            }

            div.addButton {
                button.ui.circular.icon.button {
                    background-color: white;
                }

                button.ui.button.textButton {
                    background-color: #F4F4F4;
                }
            }
        }
    }
}