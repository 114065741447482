.userPracticeInfo {
    position: relative;
    top: 100px;

    span.title {
        display: block;
        font-size: 1.5em;
        font-weight: 700;
        line-height: 2em;
    }

    .box {
        background-color: #F4F4F4;
        border-radius: 5px;
        padding: 30px;
        margin-bottom: 30px;

        span.title {
            display: none;
            font-size: 1em;
            font-weight: 700;
            line-height: 1.25em;
        }

        span.subtitle {
            display: block;
            font-size: 1em;
            line-height: 1.25em;
            color: #747474;
        }

        span.lastPracticeOn, span.lastPracticeOnDate {
            display: none;
            font-size: 0.9em;
            line-height: 2em;
        }

        div.periodStartedOn {
            span.lastPracticeOn, span.periodStartedOn {
                display: inline-block;
                font-size: 0.8em;
                font-weight: 700;
                line-height: 0.75em;
                width: 50%;
            }

            span.lastPracticeOnDate, span.periodStartedOnDate {
                display: inline-block;
                font-size: 1em;
                line-height: 0.75em;
                width: 50%;
            }
        }

        .stats {
            text-align: center;

            span.practiceHoursTotal, span.teacherPractices, span.otherPractices {
                display: inline-block;
                width: 33%;
                font-size: 0.8em;
                line-height: 1.25em;
                padding-left: 5px;

                &:last-child {
                    padding-left: 0;
                }
            }

            span.practiceHoursTotalData, span.teacherPracticesData, span.otherPracticesData {
                display: inline-block;
                width: 33%;
                font-size: 1.5em;
                line-height: 2em;
            }
        }
    }

    @media (max-width: '768px') {
        top: 0;

        span.title {
            display: none;
        }

        .box {
            padding: 15px;

            span.title {
                display: block;
            }

            span.subtitle {
                display: none;
            }

            span.lastPracticeOn, span.lastPracticeOnDate {
                display: inline;
            }

            div.periodStartedOn {
                span.lastPracticeOn, span.lastPracticeOnDate {
                    display: none;
                }

                span.periodStartedOnDate {
                    text-align: right;
                }
            }
        }
    }
}