.userTrackingOptions {
    position: relative;
    top: 100px;

    .title {
        display: block;
        font-weight: 700;
        font-size: 1.5em;
        margin-bottom: 30px;
    }

    .description {
        display: block;
        position: relative;
        left: 93px;
        width: 400px;
        font-weight: 400;
        font-size: 0.75em;
        color: #747474;
        margin: 15px 0;
    }

    .ui.toggle.checkbox {
        top: calc(50% - 0.75rem);
        left: 30px;

        input:checked~.box:before,
        input:checked~label:before,
        input:focus:checked~.box:before,
        input:focus:checked~label:before {
            background-color: #00B595 !important;
        }
    }

    @media (max-width: '768px') {
        border-top: 1px solid #F4F4F4;
        border-bottom: 1px solid #F4F4F4;
        height: 57px;
        margin: 15px 0;
        top: 0;

        .title, .description {
            display: none;
        }

        .ui.toggle.checkbox {
            left: 0;
        }
    }
}