.functionality {
    margin-top: 15px;
    background-color: rgba(231, 209, 206, 0.2);
    border-radius: 10px;
    padding: 15px 15px;

    img.ui.image {
        position: relative;
        display: inline-block;
        max-width: calc(50% - 15px);
        max-height: 350px;
        padding: 0 15px;

        &.hide {
            display: none;
        }
    }

    .functionalityTextBox {
        position: relative;
        display: inline-block;
        width: calc(50% - 15px);
        text-align: left;
        vertical-align: middle;
        padding: 0 15px;

        .functionalityTitle {
            display: block;
            font-size: 2em;
            line-height: 1.5em;
            font-weight: 700;
        }

        .functionalityText {
            display: block;
            font-size: 1.25em;
            line-height: 1.5em;
        }
    }

    @media (max-width: '768px') {
        padding: 15px;

        img.ui.image, .functionalityTextBox {
            display: block;
            width: 100%;
            max-width: 100%;
            max-height: 100%;

            &.left {
                display: block;
            }

            &.right {
                display: none;
            }
        }
    }
}