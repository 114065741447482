.folderListItem{
    padding: 0!important;
}

.folderListItemContent {
  display: flex;
  align-items: center;
  gap: 1rem;

  position: relative;

  padding: 1rem!important;

  font-size: medium;
  font-weight: 500;
  color: var(--brand-primary);

  p{
    margin-bottom: 0!important;
  }
}

.folderListItemButton{
  aspect-ratio: 1 / 1;

  min-width: 30px!important;
  min-height: 30px!important;
  width: 30px;
  height: 30px;

  padding: 0!important;
  border: 0!important;
  background-color: transparent!important;

  display: flex;
  align-items: center;
  justify-content: center;

  cursor: pointer;
}

.folderListItemButton i{
  padding: 0!important;
  margin: 0!important;
}