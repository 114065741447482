.setupSecurity {
    padding-bottom: 25px;

    .emailBox {
        .correctMessage {
            background: var(--brand-positive);
            padding: 10px;
            border-radius: 5px;
            margin-top: 15px;
            margin-bottom: 15px;
        }
    }
}