.topMenuContainer {
    position: relative;
    top: 0;
    z-index: 10;
    position: sticky;
    background-color: white;
    transition: 0.2s;

    .ui.container {
        .topMenu {
            height: 100px;
            padding: 30px 0;
            vertical-align: middle;

            &.fixed {
                height: 55px;
                padding: 10px 0;
            }

            .logoBox {
                display: inline-block;
                position: relative;
                padding: 8px 0;
                width: 135px;
                height: 36px;
            }

            .options {
                display: inline-block;
                position: relative;
                width: calc(100% - 135px - 300px);
                padding: 8px 30px;
                height: 36px;
                top: -13px;

                .link {
                    display: inline-block;
                    width: 50%;
                    text-align: center;

                    a {
                        color: #1fd5b9;
                        font-size: 1.25em;
                    }
                }
            }

            .buttons {
                display: inline-block;
                position: absolute;
                right: 0;
                width: 300px;
                text-align: right;
            }
//TODO: poner humburguesa en mobile con los botones de inisiar sesión y login y las opciones del menú
            @media (max-width: '768px') {
                .options {
                    display: none;
                }
            }

            @media (max-width: '460px') {
                height: 100px;
                padding: 10px 0;

                .logoBox {
                    display: block;
                    position: relative;
                    margin: auto;
                }

                .buttons {
                    display: block;
                    position: relative;
                    width: 100%;
                    text-align: center;
                }

            }
        }
    }
}