.horizontalPlanCard {
    position: relative;
    width: 100%;
    min-height: 125px;
    height: auto;
    background-color: white;
    color: black;
    box-sizing: border-box;
    border: 1px solid var(--brand-primary-extra-light);
    border-radius: 5px;
    margin: 20px auto;
    vertical-align: middle;
    cursor: pointer;

    &.selected {
        border: 1px solid #00FFD2;
    }

    &::before {
        content: attr(data-ribbon);
        position: absolute;
        font-weight: 700;
        font-size: 0.75em;
        line-height: 2.5em;
        top: -52px;
        left: calc(100% - 55px);
        transform-origin: bottom left;
        background-color: transparent;
        border: 25px solid transparent;
        border-bottom: 25px solid #FFE600;
        display: block;
        box-sizing: border-box;
        box-shadow: 1px 5px 2px -3px #000;
        height: 0;
        width: 80px;
        transform: rotate(45deg);
        text-align: center;
    }

    &.hideRibbon::before {
        display: none;
    }

    img.ui.image {
        position: relative;
        display: inline-block;
        width: 60px;
        transform: translateY(-40px);
        left: 30px;
    }

    .content {
        position: relative;
        display: inline-block;
        width: calc(100% - 125px);
        left: 60px;

        .planTitle {
            display: block;
            font-weight: 700;
            font-size: 1.5em;
            line-height: 1.75em;
        }

        .manageText {
            display: block;
            font-size: 1em;
            line-height: 1.25em;
        }

        .oldPrice {
            display: block;
            font-size: 0.75em;
            line-height: 1em;
            text-decoration: line-through;
        }

        .priceBox {
            .newPrice {
                font-weight: 700;
                font-size: 2em;
                line-height: 1.75em;
            }

            .periodicity {
                font-size: 1em;
                line-height: 1.25em;
            }

            .forEver {
                font-size: 1em;
                line-height: 1.25em;
            }
        }
    }

    &.disabled {
        color: var(--brand-primary-light);
        cursor: not-allowed;

        img.ui.image {
            opacity: .5;
        }
    }

    @media (max-width: '768px') {
        text-align: center;
        padding: 15px;

        img.ui.image {
            display: block;
            width: 100px;
            left: 50%;
            margin: 10px 0;
            transform: translateX(-50%);
        }

        .content {
            position: relative;
            display: block;
            width: 100%;
            left: 0;

            .manageText {
                line-height: 1.75em;
            }

            .oldPrice {
                line-height: 2.75em;
            }
        }

        .priceBox {
            .forEver {
                display: block;
                line-height: 1.75em;
            }
        }
    }
}