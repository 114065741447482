.faqItem {
    margin-bottom: 35px;

    .question {
        font-weight: 600;
        font-size: 1.5em;
        line-height: 1.5em;
        position: relative;

        span {
            display: inline-block;
            width: calc(100% - 40px);
        }

        i.angle.icon.expandIcon {
            float: right;
        }
    }

    .answer {
        font-size: 1.25em;
        line-height: 1.25em;
        display: none;

        &.active {
            display: inline;
        }
    }
}