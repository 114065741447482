.countdown {
    display: block;
    font-size: 2.25em;
    line-height: 1.5em;
    font-weight: 600;
    color: #FFE600;
    margin: 40px 0;

    &.expired {
        color: red;
    }
}