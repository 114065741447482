.inner {
    .p-flex {
        .p-content {
            .a-wrapper {
                .a-f-wrapper {
                    .listDivision {
                        height: 38px;
                        width: 100%;
                        background: none;

                        span {
                            position: relative;
                            padding: 1.15em 1em;
                            top: 0.5em;
                            line-height: 1em;
                            font-weight: 700;
                        }

                        @media (min-width: '768px') {
                            background: #F4F4F4;
                        }
                    }

                    @media (max-width: '768px') {
                        &.editMode {
                            margin-bottom: 50px;
                        }
                    }
                }
            }
        }
    }
}