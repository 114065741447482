.userMainInfo {
    position: relative;
    img.ui.avatar.image {
        width: 120px;
        height: 120px;
        margin-right: 20px;
        display: inline-block;
        object-fit: cover;
        z-index: 2;

        &.broken {
            z-index: 0;
            visibility: hidden;
        }

        @media (max-width: '768px') {
            display: block;
            margin: auto;
        }
    }

    span.initials {
        text-align: center;
        display: inline-block;
        position: absolute;
        border-radius: 50%;
        border: 1px solid #F4F4F4;
        padding: 0 0.4em;
        color: black;
        background-color: white;
        width: 120px;
        height: 120px;
        z-index: 1;
        left: 0;
        font-weight: 700;
        font-size: 4em;
        line-height: 2em;

        &.visible {
            z-index: 2;
        }

        @media (max-width: '768px') {
            top: 63px;
            left: 50%;
            transform: translateX(-50%);
        }
    }

    span {
        font-weight: 700;
        font-size: 2em;
        line-height: 2.25em;
        position: absolute;

        &.name {
            top: -12px;
        }

        &.surname {
            top: 24px;
        }

        &.status {
            top: 90px;
            left: 160px;
            font-weight: 400;
            font-size: 1em;
            line-height: 1.25em;
        }

        @media (max-width: '768px') {
            &.name, &.surname {
                position: relative;
                display: block;
                text-align: center;
                top: 0;
                left: 0;
                margin: auto;
                font-size: 1.25em;
                line-height: 1.5em;
            }

            &.status {
                position: relative;
                text-align: center;
                top: -8px;
                left: calc(50% - 18px);
                margin: auto;
            }
        }
    }

    div.status {
        position: absolute;
        top: 92px;
        left: 140px;
        background-color: red;
        height: 1em;
        width: 1em;
        border-radius: 50%;
        &.linked {
            background-color: #69FF5C;
        }

        @media (max-width: '768px') {
            position: relative;
            text-align: center;
            top: 10px;
            left: calc(50% - 40px);
            font-size: 1.25em;
            line-height: 1.5em;
        }
    }

    div.isPremium {
        position: absolute;
        margin-left: 140px;
        text-align: center;
        background-color: #F4F4F4;
        width: 200px;
        height: 45px;
        border-radius: 5px;

        &.mobile {
            display: none;
        }

        span {
            position: relative;
            color: #00B595;
            font-size: 1em;
            line-height: 3em;
        }

        @media (max-width: '768px') {
            position: relative;
            left: 0;
            top: 0;
            margin: auto;
            width: 100%;

            &.desktop {
                display: none;
            }

            &.mobile {
                display: block;
            }
        }
    }

    div.actions {
        position: absolute;
        right: 0;
        top: 0;

        button {
            width: 81px;
            height: 77px;

            span {
                position: relative;
                font-weight: 400;
                font-size: 12px;
                line-height: 1.43em;
            }

            &:last-child {
                top: 4px;
            }

            @media (max-width: '768px') {
                &.desktopSendMessage {
                    display: none;
                }
            }
        }

        @media (max-width: '768px') {
            position: relative;
            width: 100%;
            text-align: center;
        }
    }

    @media (max-width: 1000px) {
        span.name, span.surname {
            font-size: 1.5em;
        }
    }
}