.registerCheckout {
    height: 100%;
    display: flex;

    img.ui.image.background {
        position: absolute;
        width: 100%;
        height: 100%;
        object-fit: cover;
    }

    .registerTab {
        position: relative;
        display: inline-block;
        width: 60%;
        padding: 5%;
        height: 100%;

        .registerSelectPlan {
            position: relative;
            margin: auto;
            padding: 15px;
            background-color: white;
            border-radius: 20px;
            top: 50%;
            transform: translateY(-50%);

            .periodicitySelector {
                text-align: center;
                background-color: #989898;
                margin: auto;
                width: 200px;
                border-radius: 5px;
                padding: 5px;

                .selector {
                    font-size: 1em;
                    font-weight: 400;
                    line-height: 1.25em;
                    color: white;
                    padding: 5px 0;
                    display: inline-block;
                    width: 50%;
                    cursor: pointer;

                    &.active {
                        color: #00B595;
                        background-color: #FFFFFF;
                        border-radius: 3px;
                    }
                }
            }

            img.ui.image.logo {
                width: 135px;
                margin: 30px auto;
            }

            .header {
                text-align: center;
                margin-bottom: 20px;

                .stepText {
                    display: block;
                    font-weight: 700;
                    font-size: 0.9em;
                    line-height: 1.1em;
                }

                .title {
                    display: block;
                    font-weight: 600;
                    font-size: 2.25em;
                    line-height: 1.5em;
                }
            }
        }
    }

    .commercialTips {
        position: relative;
        display: flex;
        flex-direction: column;
        justify-content: center;
        width: 40%;
        padding: 15px;

        .benefit {
            position: relative;
            display: inline-block;

            img {
                width: 60px;
                display: inline-block;
                margin-right: 15px;
            }

            .benefitText {
                display: inline-block;
                text-align: center;
                vertical-align: middle;
                width: calc(100% - 75px);
                margin-bottom: 30px;

                .title {
                    display: block;
                    font-size: 1.5em;
                    line-height: 1.5em;
                    font-weight: 600;
                }

                .text {
                    display: block;
                    font-size: 1.25em;
                    line-height: 1.25em;
                }
            }
        }
    }

    @media (max-width: '768px') {
        .registerTab {
            width: 100%;
            height: 100%;
            padding: 0;

            .registerSelectPlan {
                border-radius: 0;
                height: 100vh;
                padding: 15px;
                vertical-align: middle;
                top: 0;
                transform: none;
            }
        }

        .commercialTips {
            display: none;
        }
    }
}