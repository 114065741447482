.practice-target-grid {
    display: flex;
    flex-wrap: wrap;
    background-color: white;
    border-radius: 5px;
    padding: 15px;
    margin-top: 15px;

    .item {
        position: relative;
        padding: 15px 15px;
        flex-shrink: 1;
        flex-grow: 1;
        flex-basis: auto;

        &.withoutImage {
            flex-basis: calc(100% - 170px);
        }

        &.withImage {
            flex-basis: 170px;
            padding: 0 15px;

            .item-content {
                flex-direction: row;
                align-items: flex-end;
            }
        }

        &.greyBackground {
            background-color: var(--brand-primary-extra-light-secondary);
        }

        .item-content {
            display: flex;
            flex-direction: column;
            align-items: flex-start;
            justify-content: center;
            height: 100%;

            .text {
                color: var(--dark-grey-2);
                font-size: 0.85em;
                text-align: left;
                display: block;
            }

            .secondary-text {
                color: var(--brand-secondary);
                font-size: 1em;
                font-weight: 700;
                text-align: left;
            }

            .w-image {
                display: flex;
                align-items: center;
                padding: 5px 5px;
                justify-content: center;

                img {
                    max-width: 50%;
                    height: 100%;
                    object-fit: contain;
                }
            }
        }
    }

    @media only screen and (max-width: 420px) {
        flex-direction: column;

        .item {
            &.withoutImage, &.withImage {
                max-width: 100%;
                max-height: 120px;

                .item-content {
                    .w-image {
                        img {
                            max-width: 100%;
                        }
                    }
                }
            }
        }
    }
}