.setupMenuContainer {
    margin-bottom: 15px;
    border-bottom: 0 !important;
    height: 57px !important;
    z-index: 8 !important;

    .setupMenu {
        padding-left: 0 !important;
        width: calc(100% - 30px) !important;
        left: -25px;
        position: relative;
        justify-content: flex-start !important;

        ul {
            display: flex;

            li > a {
                @media (max-width: 425px) {
                    font-size: 0.75em;
                }
            }
        }
    }
}