.userMessages {
    position: relative;
    top: 100px;
    padding-bottom: 15px;;

    span.title {
        font-size: 1.5em;
        font-weight: 700;
        line-height: 2em;
    }

    .box {
        background-color: #F4F4F4;
        border-radius: 5px;
        padding: 30px;
        margin-bottom: 30px;

        span.subtitle {
            display: block;
            font-size: 1em;
            line-height: 1.25em;
            color: #747474;
        }
    }

    @media (max-width: '768px') {
        top: 0;

        span.title {
            display: block;
        }

        .box {
            padding: 15px;

            span.subtitle {
                display: block;
                font-size: 1em;
                line-height: 1.25em;
                font-weight: 700;
                color: black;
            }
        }
    }
}