.setupAccount {
    padding-bottom: 25px;

    .pictureBox, .videoBox {
        margin-bottom: 30px;

        label {
            display: block;
            margin: 0 0 0.28571429rem 0;
            color: rgba(0,0,0,.87);
            font-weight: 700;
            text-transform: none;
            font-family: PoppinsBold;
            font-size: .92857143em;
        }
    }
}