.pictureContent, .videoContent {
    margin-top: 20px;

    .picture {
        width: 72px;
        height: 72px;
        display: inline-block;
        border-radius: 10px;
        overflow: hidden;
        background: #fff;
        margin-right: 10px;

        img {
            width: 100%;
            height: 100%;
            object-fit: cover;
        }
    }

    .video {
        width: 200px;
        height: 113px;
        display: inline-block;
        border-radius: 10px;
        overflow: hidden;
        background: #fff;
        margin-right: 10px;

        video {
            width: 100%;
            height: 100%;
        }
    }

    .buttons {
        position: relative;
        float: right;

        button.ui.small.button {
            overflow: hidden;
            position: relative;
            vertical-align: top;

            span {
                cursor: pointer;
            }

            input.hide-input {
                opacity: 0;
                position: absolute;
                top: 0;
                width: 100%;
                bottom: 0;
                z-index: 1;
                left: 0;
                cursor: pointer;
            }
        }
    }

    div.recommendedSizeAndFormat {
        display: inline-block;
        font-weight: 400;
        font-size: 0.8em;
        line-height: 2em;
        color: #C4C4C4;
        vertical-align: top;
    }
}

@media (max-width: '768px') {
    .pictureContent {
        div.recommendedSizeAndFormat {
            vertical-align: bottom;
        }
    }

    .videoContent {
        .video {
            width: 100%;
            height: auto;
            display: block;
            margin-right: 0;
            margin-bottom: 10px;
        }
    }
}