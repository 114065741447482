.studentList {
    max-width: 700px;
    margin: auto;

    div.studentsEmpty {
        padding: 15px 0;
        color: #747474;
        font-size: 1em;
        font-weight: 500;
        line-height: 1.25em;
    }

    .addStudent {
        .ui.button {
            padding: 0;
        }
    }
}