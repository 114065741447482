.ui.selection.list {

    .item.selectableListItem {
        position: relative;
        padding-bottom: 0.5em;
        padding-top: 0.5em;
        margin-bottom: 0;
        border-bottom: 0;
        border-radius: 0;

        @media (min-width: '768px') {
            border-bottom: 1px solid #F4F4F4;

            &:last-child {
                border-bottom: 0;
            }
        }

        div.ui.fitted.checkbox {
            display: none;
            margin-right: 2em;
            top: 1em;

            @media (min-width: '768px') {
                display: inline;
            }

            .editMode {
                display: inline;
            }
        }

        &.editMode {
            div.ui.fitted.checkbox {
                display: inline;
            }

            .content {
                width: calc(100% - 85px);

                &.withActions {
                    width: calc(100% - 115px);

                    &.desktopActions_2 {
                        width: calc(100% - 142px);
                    }

                    @media (max-width: '768px') {
                        &.desktopActions_2.mobileActions_0, &.desktopActions_1.mobileActions_0 {
                            width: calc(100% - 60px);
                        }

                        &.desktopActions_2.mobileActions_1 {
                            width: calc(100% - 115px);
                        }

                        &.desktopActions_2.mobileActions_2 {
                            width: calc(100% - 142px);
                        }
                    }
                }
            }
        }

        img.ui.image {
            border-radius: 5px;
            border: 3px double #F4F4F4;
            height: 3.25em;
            width: 3.25em;
            display: inline-block;
            margin-right: 0.25em;
            object-fit: cover;
            z-index: 2;

            &.avatar {
                border-radius: 55%;
                border: none;
            }

            &.broken {
                z-index: 0;
                visibility: hidden;
            }
        }

        span.initials {
            text-align: center;
            display: inline-block;
            position: absolute;
            border-radius: 5px;
            border: 3px double #F4F4F4;
            padding: 0.8em;
            color: black;
            margin-right: 0.25em;
            background-color: white;
            height: 3.25em;
            width: 3.25em;
            z-index: 1;

            &.avatar {
                border-radius: 50%;
                border: 1px solid #F4F4F4;
                padding: 1em;
            }

            &.visible {
                z-index: 2;
            }
        }

        .content {
            padding-top: 0.5em;
            width: calc(100% - 60px);
            display: inline-block;

            @media (min-width: '768px') {
                width: calc(100% - 85px);
            }

            .header, .description {
                text-overflow: ellipsis;
                overflow: hidden;
                white-space: nowrap;
            }

            &.withActions {
                width: calc(100% - 85px);

                &.desktopActions_1,
                &.desktopActions_2 {
                    width: calc(100% - 145px);

                    &.withComponents {
                        width: calc(100% - 350px);
                    }
                }

                @media (max-width: '768px') {
                    &.mobileActions_0, &.mobileActions_0.withComponents {
                        width: calc(100% - 60px);
                    }

                    &.mobileActions_1, &.mobileActions_1.withComponents {
                        width: calc(100% - 115px);
                    }

                    &.mobileActions_4, &.mobileActions_4.withComponents {
                        width: calc(100% - 177px);
                    }
                }
            }

            &.withComponents {
               
                &.desktopActions_1 {
                    width: calc(100% - 350px);   
                }
            }

        }

        .itemComponents {
            position: relative;
            display: inline;
            top: 3px;
            margin-right: 12px;
            margin-left: 12px;
            vertical-align: middle;

            @media (max-width: '768px') {
                display: none;
            }
        }

        .itemFns {
            display: inline;

            i {
                font-size: 1.5em;
                top: 12px;
                position: relative;

                &.hideOnDesktop {
                    display: none;
                }
            }

            @media (max-width: '768px') {
                &.mobileActions_0 {
                    display: none;
                }

                i.hideOnDesktop {
                    display: inline;
                }

                i.hideOnMobile {
                    display: none;
                }
            }
        }

        &.selected, &:hover {
            background: rgba(0, 181, 149, 0.1)!important;

            .ui.checkbox input:checked~.box:before, .ui.checkbox input:checked~label:before {
                background: #00B595;
                border: 0;
            }

            .ui.checkbox input:checked~.box:after, .ui.checkbox input:checked~label:after {
                color: white;
            }
        }

        &.draggable {
            .itemFns>i {
                cursor: pointer;
            }

            i.moveIcon, .itemFns>i {
                display: inline;
                position: relative;
                font-size: 1.5em;
                top: 5px;
                margin-right: 5px;
                cursor: pointer;

                &.hideOnDesktop {
                    display: none;
                }

                @media (max-width: '768px') {
                    &.mobileActions_0 {
                        display: none;
                    }

                    &.hideOnDesktop {
                        display: inline;
                    }

                    &.hideOnMobile {
                        display: none;
                    }
                }
            }

            @media (max-width: '768px') {
                .content {
                    &.mobileActions_1 {
                        width: calc(100% - 117px);
                    }

                    &.mobileActions_4 {
                        width: calc(100% - 182px);
                    }
                }
            }
        }
    }

    .item.selectableListItem.draggable {
        .content {
            vertical-align: middle;

            .header {
                font-weight: 700;
            }
        }
    }
}