.plans {
    .expiredLicenseText {
        padding: 25px;

        ul {
            padding-left: 30px;
        }
    }

    .periodicitySelector {
        text-align: center;
        background-color: #989898;
        margin: auto;
        width: 200px;
        border-radius: 5px;
        padding: 5px;

        .selector {
            font-size: 1em;
            font-weight: 400;
            line-height: 1.25em;
            color: white;
            padding: 5px 0;
            display: inline-block;
            width: 50%;
            cursor: pointer;

            &.active {
                color: #00B595;
                background-color: #FFFFFF;
                border-radius: 3px;
            }
        }
    }
}